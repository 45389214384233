import React, { useEffect } from "react";
import aboutBanner from "../Assets/Images/about-banner.jpg";
import doubleQuotesA from "../Assets/Images/double-quotes-a.png";
import doubleQuotesB from "../Assets/Images/double-quotes-b.png";
import bgStoryA from "../Assets/Images/bg-story-a.png";
import bgStoryB from "../Assets/Images/bg-story-b.png";
import jurneyA from "../Assets/Images/jurney-a.png";
import jurneyB from "../Assets/Images/jurney-b.png";
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>About | 15Blades</title>
        <meta
          name="description"
          content="We are a clinician only peer to peer medical forum platform that focuses on micro-learning to keep pace with todays healthcare professional. We have created a micro-learning environment where short form videos and posts are curated for your experience. We welcome healthcare professionals to participate as a life-long student and educator to advance the spread of medical knowledge and discourse. We have created a micro-learning environment where short form videos and posts are curated for your experience. We welcome healthcare professionals to participate as a life-long student and educator to advance the spread of medical knowledge and discourse.Explore online surgery courses and more. Develop new skills to advance your career with 15 blades."
        />
        <meta
          name="keywords"
          content="15blades about, 15 blades about, 15 blades about us, medical media educational platform, micro-learning, empowering healthcare through connectivity, "
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="About | 15Blades" />
        <meta
          property="og:description"
          content="We are a clinician only peer to peer medical forum platform that focuses on micro-learning to keep pace with todays healthcare professional. We have created a micro-learning environment where short form videos and posts are curated for your experience. We welcome healthcare professionals to participate as a life-long student and educator to advance the spread of medical knowledge and discourse. We have created a micro-learning environment where short form videos and posts are curated for your experience. We welcome healthcare professionals to participate as a life-long student and educator to advance the spread of medical knowledge and discourse.Explore online surgery courses and more. Develop new skills to advance your career with 15 blades."
        />
      </Helmet>
      <Navbar />
      <section className="about-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <div className="text-content ">
                    <h2> Empowering Healthcare Through Connectivity</h2>
                    <p className="mx-auto">
                      Learn more about our story and the hard working people
                      behind 15Blades
                    </p>
                  </div>
                </div>
              </div>

              <img className="img-fluid" src={aboutBanner} />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container quotes">
          <div className="row">
            <div className="col-lg-6 col-md-10 mx-auto">
              <img className="double-quotes-a" src={doubleQuotesA} />
              <h4>
                We are a clinician only peer to peer medical forum platform that focuses on micro-learning to keep pace with todays healthcare professional. We have created a micro-learning environment where short form videos and posts are curated for your experience. We welcome healthcare professionals to participate as a life-long student and educator to advance the spread of medical knowledge and discourse.{" "}
              </h4>
              <img className="double-quotes-b" src={doubleQuotesB} />
            </div>
          </div>
        </div>
      </section>

      <section className="story">
        <img src={bgStoryA} className="img-fluid bg-story-a" />
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mx-auto">
              <div>
                <h6>Our Story</h6>
                <h2>It all started in the Fall of 2021</h2>
                <p>
                  On a Thursday morning, where I had a short 3-4 minute
                  conversation with Nancy, my hardware Rep for the case, before
                  I scrubbed in. I voiced my interest in crowd-sourcing surgical
                  video content for the younger generation of surgeons, but with
                  no real connections to industry or the tech world for such an
                  endeavour. We briefly talked and I went on to finish my
                  surgical cases for the day. To my surprise, I received a
                  phone-call a couple days day later which led to a dinner
                  meeting at a Pizza Parlour with Greg and subsequent
                  connections to an app developer.
                </p>
              </div>
            </div>
          </div>
        </div>
        <img src={bgStoryB} className="img-fluid bg-story-b" />
      </section>

      <section className="journey">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="journey-image-one">
                <img src={bgStoryB} className="img-fluid journey-img-a" />
                <img
                  src={jurneyA}
                  className="img-fluid journey-img-main"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <h5>
                <span>Journey</span> continues...
              </h5>
              <p>
                It took another month or two before I realized the cost of the
                project, the enormity of the work that needed to be done and how
                low my confidence level was at the time to take on this
                Godzilla-of a project. I contacted my old friend Amir to see if
                he was interested, and he jumped on almost immediately. I got to
                say, what a confidence booster that was.
              </p>
              <p className="pg-spacing">
                In the course of the year or so, the four of us got together,
                dug in and began to piecemeal this beast. We started to feel out
                the details as the project started to take from, not only on the
                tech and app side of things, but also the business side of
                things.{" "}
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 ">
              <h5>
                The <span>more</span> the merrier...
              </h5>
              <p>
                With every investor and partner who decided to team up with us,
                this project began to come to life. Today, the project is
                ongoing, but everyday it has been better than the day before it.
                I have to say, what an exhilarating ride so far! The journey so
                far has been both enjoyable, humbling and at the same time quite
                addicting for me personally.
              </p>
              <p className="pg-spacing">
                We want to say to all those who supported us so far,{" "}
                <span>big thanks to you all.</span>
              </p>
            </div>
            <div className="col-lg-6 col-md-12 ">
              <div className="journey-image-one">
                <img src={bgStoryB} className="img-fluid journey-img-b" />
                <img
                  src={jurneyB}
                  className="img-fluid journey-img-main mt-4 mb-4"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;
