import React, { useState } from 'react'
import logo from '../Assets/Images/logo.svg'
import { Link, NavLink } from 'react-router-dom'
import RequestModal from './RequestModal'

function Navbar() {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleNavbar = () => {
        setIsCollapsed(!isCollapsed);
    };
    const closeNavbar = () => {
        setIsCollapsed(true);
    };
    const handleLinkClick = () => {
        // Replace the URL with the desired destination
        const destinationURL = 'https://15bladesmerch.com/';

        // Open the link in a new tab
        window.open(destinationURL, '_blank');
    };
    return (
        <>
            <section>
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light mt-4">
                        <div className="container-fluid">
                            <Link to="/" onClick={() => window.scrollTo(0, 0)}><img src={logo} className="img-fluid nav-logo" /></Link>
                            <button className="navbar-toggler" type="button" onClick={toggleNavbar} >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={`collapse navbar-collapse mob-menu ${isCollapsed ? '' : 'show'}`}>
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <NavLink className="nav-link" aria-current="page" to="/" onClick={closeNavbar}>Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link " aria-current="page" to="/about" onClick={closeNavbar}>About Us</NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink className="nav-link " aria-current="page" to="/advisoryboard" onClick={closeNavbar}>Advisory Board</NavLink>
                                    </li>

                                    {/* <li className="nav-item">
                                    <NavLink className="nav-link " aria-current="page" to="/curators" onClick={closeNavbar}>Curators</NavLink>
                                </li> */}
                                    <li className="nav-item">
                                        <a className="nav-link " href="https://15bladesmerch.com/" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                                            Merchandise
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link " aria-current="page" to="/contact" onClick={closeNavbar}>Contact Us</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <RequestModal />
                                        {/* <button type="button" className="btn btn-primary" >Request VIP Access</button> */}
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </nav>
                </div>
            </section>
        </>
    )
}

export default Navbar