import React, { useEffect, useState } from "react";
import bgStoryA from "../Assets/Images/bg-story-a.png";
import phoneIcon from "../Assets/Images/phone-icon.png";
import email from "../Assets/Images/email.png";
import location from "../Assets/Images/location.png";
import infoBg from "../Assets/Images/info-bg.png";
import circle from "../Assets/Images/circle.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { Collapse } from "react-bootstrap";
import md5 from "md5";
import { Helmet } from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function Contact() {
  const contactFormURL = process.env.REACT_APP_API_URL+"/website/contactus";
  const apiKey = process.env.REACT_APP_API_KEY;
  const recaptchaRef = React.useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const timestamp = Date.now();
  const key= timestamp+apiKey;
  const encryptedBackendKey = md5(key)


  const headers = {
    'X-Secret-Key': encryptedBackendKey,
  };

  const formSubmit = async (formData) => {
    const token = await recaptchaRef.current.executeAsync();
    try {
      const data = {...formData, ...{timestamp},...{token}}
      const response = await axios.post(contactFormURL, data, { headers });

      if (response.data.status === 1) {
        Swal.fire({
          icon: "success",
          title: response.data.message,
          // text: 'Thank you for your submission.',
          timer: 3000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error submitting the form",
          text: response.data.message,
          timer: 3000,
        });
      }

      reset();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error submitting the form",
        text: error,
        timer: 3000,
      });
      console.error("Error submitting the form:", error);
    }
  };

  const [openIndices, setOpenIndices] = useState([]);

  const buttonsAndCollapses = [
    {
      buttonText: "What is your platform about?",
      collapseText: "<strong>This is the second item's accordion body.</strong>It is hidden by default, until the collapse plugin addsthe appropriate classes that we use to style eachelement. These classes control the overall appearance,as well as the showing and hiding via CSS transitions.You can modify any of this with custom CSS or overridingour default variables. It's also worth noting that justabout any HTML can go within the<code>.accordion-body</code>, though the transition doeslimit overflow.",
    },
    {
      buttonText: "Are the curated articles fact-checked and accurate?",
      collapseText: "<strong>This is the second item's accordion body.</strong>It is hidden by default, until the collapse plugin addsthe appropriate classes that we use to style eachelement. These classes control the overall appearance,as well as the showing and hiding via CSS transitions.You can modify any of this with custom CSS or overridingour default variables. It's also worth noting that justabout any HTML can go within the<code>.accordion-body</code>, though the transition doeslimit overflow.",
    },
    {
      buttonText: "How can I contact a curator for specific questions about a topic?",
      collapseText: "<strong>This is the second item's accordion body.</strong>It is hidden by default, until the collapse plugin addsthe appropriate classes that we use to style eachelement. These classes control the overall appearance,as well as the showing and hiding via CSS transitions.You can modify any of this with custom CSS or overridingour default variables. It's also worth noting that justabout any HTML can go within the<code>.accordion-body</code>, though the transition doeslimit overflow.",
    },
    {
      buttonText: "Is your platform free to use?",
      collapseText: "<strong>This is the second item's accordion body.</strong>It is hidden by default, until the collapse plugin addsthe appropriate classes that we use to style eachelement. These classes control the overall appearance,as well as the showing and hiding via CSS transitions.You can modify any of this with custom CSS or overridingour default variables. It's also worth noting that justabout any HTML can go within the<code>.accordion-body</code>, though the transition doeslimit overflow.",
    },
    {
      buttonText: "How can I become a contributor?",
      collapseText: "<strong>This is the second item's accordion body.</strong>It is hidden by default, until the collapse plugin addsthe appropriate classes that we use to style eachelement. These classes control the overall appearance,as well as the showing and hiding via CSS transitions.You can modify any of this with custom CSS or overridingour default variables. It's also worth noting that justabout any HTML can go within the<code>.accordion-body</code>, though the transition doeslimit overflow.",
    },
    {
      buttonText: "What does VIP access offer?",
      collapseText: "<strong>This is the second item's accordion body.</strong>It is hidden by default, until the collapse plugin addsthe appropriate classes that we use to style eachelement. These classes control the overall appearance,as well as the showing and hiding via CSS transitions.You can modify any of this with custom CSS or overridingour default variables. It's also worth noting that justabout any HTML can go within the<code>.accordion-body</code>, though the transition doeslimit overflow.",
    },
  ];

  const handleButtonClick = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | 15Blades</title>
        <meta
          name="description"
          content="Contact us if you have any questions,partnership inquiries, feedback, any suggestions on how we could improve the experience for you or just want to say hello we're here to listen and respond. Complete our simple online form here today."
        />
        <meta
          name="keywords"
          content="contact us, 15 blades contact us, contact, contact form, form, reach out to us"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact Us | 15Blades" />
        <meta
          property="og:description"
          content="Contact us if you have any questions,partnership inquiries, feedback, any suggestions on how we could improve the experience for you or just want to say hello we're here to listen and respond. Complete our simple online form here today."
        />
      </Helmet>
      <Navbar />
      <section className="about-banner contact-us">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <div className="text-content ">
                    <h2>Reach Out To Us</h2>
                    <p className="mx-auto">
                      We are thrilled that you want to get in touch with our
                      team. Whether you have questions, feedback, partnership
                      inquiries, or just want to say hello, we're here to listen
                      and respond.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative">
        <img src={bgStoryA} className="reach-bg" />
        <div className="container">
          <div className="contact-form-section">
            <div className="row">
              <div className="col-lg-5">
                <div className="info">
                  <div className="contact-info">
                    <h3>Contact Information</h3>
                    <p>Say something to start a live chat!</p>
                    <ul>
                      {/* <li>
                        <span>
                          <img src={phoneIcon} />
                        </span>
                        <p>(325)469-2701</p>
                      </li> */}
                      <li>
                        <span>
                          <img src={email} />
                        </span>
                        <p className="noselect">contact@15Blades.com</p>
                      </li>
                      {/* <li>
                        <span>
                          <img src={location} />
                        </span>
                        <p>
                          {" "}
                          1829 Reisterstown Rd. Suite 350, Pikesville, MD 21208
                        </p>
                      </li> */}
                    </ul>
                  </div>

                  <div className="contact-media">
                    <ul>
                      <li className="media-one">
                        <a href="#">
                        <i class="fa-brands fa-x-twitter"></i>
                        </a>
                      </li>
                      <li className="media-two">
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li className="media-one">
                        <a href="#">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <img src={infoBg} className="img-fluid info-bg" />
                </div>
              </div>

              <div className="col-lg-7 d-flex align-items-center">
                <form className="mob-form" onSubmit={handleSubmit(formSubmit)}>
                  <div className="form-row">
                    <div className="input-data">
                      <input
                        type="text"
                        // required
                        {...register("first_name", {
                          required: "First name is required",
                        })}
                      />
                      <div className="underline"></div>
                      <label htmlFor="">First Name <span className="error">*</span></label>
                      {errors.first_name && (
                        <span className="error">
                          {errors.first_name.message}
                        </span>
                      )}
                    </div>
                    <div className="input-data">
                      <input
                        type="text"
                        // required
                        {...register("last_name", {
                          required: "Last name is required",
                        })}
                      />
                      <div className="underline"></div>
                      <label htmlFor="">Last Name <span className="error">*</span></label>
                      {errors.last_name && (
                        <span className="error">
                          {errors.last_name.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-data">
                      <input
                        type="text"
                        // required
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
                            message: "Please enter a valid email address",
                          },
                        })}
                      />
                      <div className="underline"></div>
                      <label htmlFor="">Email <span className="error">*</span></label>
                      {errors.email && (
                        <span className="error">{errors.email.message}</span>
                      )}
                    </div>
                    <div className="input-data">
                      <input
                        type="text"
                        // required
                        {...register("phone_number", {
                          required: "Phone number is required",
                          pattern: {
                              value:  /^(?:\+\d{1,3}[. -]?)?(\([2-9]\d{2}\)|[2-9]\d{2})[. -]?[2-9]\d{2}[. -]?\d{4}$/,
                            message: "Please enter a valid phone number",
                          },
                        })}
                      />
                      <div className="underline"></div>
                      <label htmlFor="">Phone Number <span className="error">*</span></label>
                      {errors.phone_number && (
                        <span className="error">
                          {errors.phone_number.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-data textarea">
                      <textarea
                        rows="8"
                        cols="80"
                        // required
                        {...register("message", {
                          required: "Message is required",
                          // pattern: {
                          //     value:  /^(?!\s+$).+$/,
                          //   message: "Please enter a valid message",
                          // }
                        })}
                      ></textarea>
                      {errors.message && (
                        <span className="error">{errors.message.message}</span>
                      )}

                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_SITE_KEY}
                      />
                      <br />
                      <div className="underline"></div>
                      <label htmlFor="">Message <span className="error">*</span></label>

                      <br />

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-style"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="faq">
  <div className="container">
    <div className="row text-center mb-4">
      <div className="col-md-12">
        <p>FAQ</p>
        <h5>General Questions</h5>
      </div>
    </div>
    <div className="row">

      <div className="col-lg-6">
        <div className="question">
          <p>What is your platform about?</p>
          <span><i className="fa-solid fa-plus"></i></span>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="question">
          <p>Are the curated articles fact-checked and accurate?</p>
          <span><i className="fa-solid fa-plus"></i></span>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="question">
          <p>How can I contact a curator for specific questions about a topic?</p>
          <span><i className="fa-solid fa-plus"></i></span>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="question">
          <p>Is your platform free to use?</p>
          <span><i className="fa-solid fa-plus"></i></span>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="question">
          <p>How can I become a contributor?</p>
          <span><i className="fa-solid fa-plus"></i></span>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="question">
          <p>What does VIP access offer?</p>
          <span><i className="fa-solid fa-plus"></i></span>
        </div>
      </div>

    </div>
  </div>
</section> */}

      {/* <section className="faq">
        <div className="container">
          <div className="row text-center mb-4">
            <div className="col-md-12">
              <p>FAQ</p>
              <h5>General Questions</h5>
            </div>
          </div>
          <div className="row">
            {buttonsAndCollapses.map((item, index) => (

              <div className="col-lg-6" key={index}>
              <div className="question">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      onClick={() => handleButtonClick(index)}
                      aria-controls={`example-collapse-text-${index}`}
                      aria-expanded={openIndices.includes(index)}
                      className="accordion-button collapsed"
                      type="button"
                    >
                      {item.buttonText}
                      <span>
                        <i className="fa-solid fa-plus"></i>
                      </span>
                    </button>
                  </h2>
                  <Collapse in={openIndices.includes(index)} dimension="height">
                    <div
                      id={`example-collapse-text-${index}`}
                      className="accordion-collapse "
                    >
                      <div
                        className="accordion-body"
                        id="example-collapse-text"
                        dangerouslySetInnerHTML={{__html: item.collapseText}}
                      >
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>

            ))}
            
          </div>
        </div>
      </section> */}

      <section className="">
        <div className="container">
          <div className="grow-card">
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <h3>Let’s Grow Together</h3>
                <p>
                  We believe in the power of growth through collaboration and
                  continuous learning. We invite you to embark on a
                  transformative journey with us, where knowledge knows no
                  bounds, and the pursuit of excellence is a shared passion.
                </p>
                {/* <button type="button" className="btn btn-primary">
                  Get Started
                </button> */}
              </div>
            </div>
            <img src={bgStoryA} className="grow-bg-a" />
            <img src={circle} className="grow-bg-b" />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
