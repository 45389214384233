import React from 'react'
import nodata from "../Assets/Images/no-data.jpg";
import { Link } from 'react-router-dom';

function NoData() {
  return (
    <div className="no-record">
        <img className="nodata" src={nodata} alt="" />
        <p>No Records found</p>
        <div className="d-flex justify-content-center">
            <Link to="/">
                <button  class="btn-secondary">Back to Home<svg class="w-6 h-6" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" stroke-linejoin="round" stroke-linecap="round"></path></svg></button>
            </Link>
        </div>
    </div>
  )
}

export default NoData