import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

function TextArea({ name, label, rules, className,placeholder }) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <div className={className}>
      {/* {label && <><label htmlFor={name} className="form-label">{label}</label></> } */}
      {label && <><label htmlFor={name}>{label}<span className="error">*</span></label></> }

      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={rules}
        render={({ field }) => (
          <textarea {...field} className="" rows="" placeholder={placeholder} />
        )}
      />
       <div className="underline"></div>
        {/* {label && <><label htmlFor={name}>{label}<span className="error">*</span></label></> } */}

      {errors[name] && <span className='error'>{errors[name].message}</span>}
    </div>
  );
}

export default TextArea;
