import { placeholder } from '@babel/types';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

function InputField(props) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <div className={props.className}>
      
        <Controller
          name={props.name}
          control={control}
          defaultValue=""
          rules={props.rules}
          render={({ field }) => (
            <input {...field} type={props.inputType || "text"} className=""   placeholder={props.placeholder || undefined}
            />
          )}
        />
        
        <div className="underline"></div>
        {/* <label htmlFor="">Phone Number <span className="error">*</span></label> */}
        {props.label && <><label htmlFor={props.name}>{props.label}<span className="error">*</span></label></> }

      {errors[props.name] && <span className='error'>{errors[props.name].message}</span>}
    </div>
  );
}

export default InputField;
