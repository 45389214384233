import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

function RadioField({ name, label, rules, options, className }) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <div className={className}>

      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={rules}
        render={({ field }) => (
          <>
          <div className='row pt-sm-2 pt-0'>
          {options?.map((option, index) => (
            <div className='col-sm-6 d-flex mt-sm-0 mt-1 pb-sm-0 pb-1 pt-sm-0 pt-1'>
              <input className='me-1'  type='radio' {...field} value={option.id}/>
              <span>{option.name}</span>
            </div>
          ))}
          </div>
          </>
        )}
      />
        {/* <div className="underline"></div> */}
        {label && <><label htmlFor={name}>{label}<span className="error">*</span></label></> }

      {errors[name] && <span className='error'>{errors[name].message}</span>}
    </div>
  );
}

export default RadioField;
