import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import InputField from "../Components/inputs/InputField";
import SelectField from "../Components/inputs/SelectField";
import TextArea from "../Components/inputs/TextArea";
import md5 from "md5";
import axios from "axios";
import Swal from "sweetalert2";
import bgStoryA from "../Assets/Images/bg-story-a.png";
import { FormProvider, useForm } from "react-hook-form";
import bbg from "../Assets/Images/15b-bg.png";
import bgImg from "../Assets/Images/bg.png";
import ReCAPTCHA from "react-google-recaptcha";
import RadioField from "../Components/inputs/RadioField";
import { DevTool } from "@hookform/devtools";

function Advertise() {
  
  const methods = useForm();

  const { handleSubmit, reset, register, setValue ,watch,resetField, formState:{errors},control } = methods;

  const url = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const recaptchaRef = React.useRef();

  const [states,setStates] = useState([]);
  const [types,setTypes] = useState([]);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    getRegister();
    window.scrollTo(0, 0);
  },[])

  const timestamp = Date.now();
  const key= timestamp+apiKey;
  const encryptedBackendKey = md5(key)

  const headers = {
    'X-Secret-Key': encryptedBackendKey,
  };
  const data = {
    'timestamp': timestamp 
  };

  const getRegister = async ()=>{
    try {
      const response = await axios.post(url+'getregisteradvertiser',{...data} ,{ headers });
        // console.log('Response:', response.data);
        if(response.data.status === 'success'){
          const statesdata = response.data.data.states?.map((item) => ({
            id: item.id,
            name: item.state_prefix,
          }));

          const adverstiserType = response.data.data.types?.map((item) => ({
            id: item.id,
            name: item.advertiser_type,
          }));
          setTypes(adverstiserType)
          setStates(statesdata);
        }else if(response.data.error == '1'){
          Swal.fire({
            icon: 'error',
            title: response.data.errormsg,
            // text: error,
            timer: 3000,
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: response.data.message,
            // text: error,
            timer: 3000,
          })
        }
    } catch (error) {
        console.error('Error:', error);
    }
  }

  const registeradvertiser = async (formdata)=>{
    setSubmitDisabled(true);
    const token = await recaptchaRef.current.executeAsync();
    try {
      
      const response = await axios.post(url+'registeradvertiser',{...data,...formdata,...{token}} ,{ headers });
        console.log('Response:', response.data);
        if(response.data.status === 1){
          Swal.fire({
            icon: 'success',
            title: response.data.message,
            // text: response.data.text,
            timer: 3000,
          })
          reset();
          window.scrollTo(0, 0);
          setSubmitDisabled(false);
        }else{
          Swal.fire({
            icon: 'error',
            title: response.data.message,
            // text: error,
            timer: 3000,
          })
          setSubmitDisabled(false);
        }
    } catch (error) {
        console.error('Error:', error);
        setSubmitDisabled(false);
    }
  }

  // Custom validation rule to check for spaces
  // const validateNoSpace = (value) => {
  //   return !/\s/.test(value) || "Spaces are not allowed";
  // };

  const fields = [
    {
      label: "Company Details",
      className: "pb-2",
      type: "heading",
      isShow: true ,
    },
    {
      name: "advertiser_type",
      label: "Advertiser Type",
      className: "input-data checkbox-row col-lg-6",
      rules: { 
        required: "Please select type",
      },
    
      type: "radiofield",
      isShow: true,
      options: types,
    },
    {
      name: "company_name",
      label: "Name",
      className: "input-data col-md-6",
      rules: { 
        required: "Please enter name",
        // validate: validateNoSpace // Attach custom validation rule
        pattern: {
          value: /^(?!\s+$).+/,
          message: "Please enter name",
        },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "company_email",
      label: "Email",
      className: "input-data col-md-6",
      rules: {
        required: "Please enter email ",
        pattern: {
          value: /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
          message: "Please enter a valid email address",
        },
        validate: {
          emailExist: async (fieldValue) => {
            const response = await axios.post(url+'checkcompanyemailexists',{ company_email: fieldValue },{ headers });
            // console.log(response.data);
            return response.data || "Email already exist";
          },
        },
      },
      type: "inputfield",
      isShow: true,
    },
   
    {
      name: "company_phone_number",
      label: "Phone Number",
      className: "input-data col-md-6",
      rules: {
        required: "Please enter phone number",
        pattern: {
          value:
            /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/,
          message: "Please enter a valid phone number",
        },
      },
      type: "inputfield",
      isShow: true,
    },

    {
      name: "company_address",
      label: "Address",
      // className: "form-input mt-2",
      className: "input-data col-md-6",
      rules: { 
        required: "Please enter address",
        // pattern: {
        //   value: /^(?!\s+$).+/,
        //   message: "Please enter address",
        // },
      },
      type: "inputfield",
      isShow: true,
    },

   

    {
      name: "company_city",
      label: "City",
      className: "input-data col-md-6",
      rules: { 
        required: "Please enter city",
        // pattern: {
        //   value: /^(?!\s+$).+/,
        //   message: "Please enter city",
        // },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "company_state",
      label: "State",
      className: "input-data select-body col-md-6",
      rules: { required: "Please select state" },
      type: "select",
      options: states,
      isShow: true ,
    },
    

    {
      name: "company_zipcode",
      label: "Zipcode",
      className: "input-data col-md-6",
      rules: {
        required: "Please enter zipcode",
        pattern: {
          value: /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnpstvxy]{1}\d{1}[A-Za-z]{1} ?\d{1}[A-Za-z]{1}\d{1})$/,
          message: "Please enter a valid zipcode",
        }
      },
      type: "inputfield",
      isShow: true,
    },
   
   
   
   
    {
      label: "Contact Details",
      className: "py-2",
      type: "heading",
      isShow: true ,
    },
    {
      name: "first_name",
      label: "First Name",
      className: "input-data col-md-6",
      rules: { 
        required: "Please enter first name",
        pattern: {
          value: /^(?!\s+$).+/,
          message: "Please enter first name",
        }, 
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "last_name",
      label: "Last Name",
      className: "input-data col-md-6",
      rules: { 
        required: "Please enter last name",
        pattern: {
          value: /^(?!\s+$).+/,
          message: "Please enter last name",
        },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "email",
      label: "Email",
      className: "input-data col-md-6",
      rules: {
        required: "Please enter email ",
        pattern: {
          value: /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
          message: "Please enter a valid email address",
        },
        validate: {
          emailExist: async (fieldValue) => {
            const response = await axios.post(url+'checkuseremailexists',{ email: fieldValue },{ headers });
            // console.log(response.data.hasData);
            return response.data || "User is already added to the system. Only VIP users can have an advertiser account.";
          },
        },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "phone_number",
      label: "Phone Number",
      className: "input-data col-md-6",
      rules: {
        required: "Please enter phone number",
        pattern: {
          value:
            /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/,
          message: "Please enter a valid phone number",
        },
      },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "address",
      label: "Address",
      // className: "form-input mt-2",
      className: "input-data col-md-6",
      rules: { required: "Please enter address" },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "city",
      label: "City",
      className: "input-data col-md-6",
      rules: { required: "Please enter city" },
      type: "inputfield",
      isShow: true,
    },
    {
      name: "state",
      label: "State",
      className: "input-data select-body col-md-6",
      rules: { required: "Please select state" },
      type: "select",
      options: states,
      isShow: true ,
    },
    {
      name: "zipcode",
      label: "Zipcode",
      className: "input-data col-md-6",
      rules: {
        required: "Please enter zipcode",
        pattern: {
          value: /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnpstvxy]{1}\d{1}[A-Za-z]{1} ?\d{1}[A-Za-z]{1}\d{1})$/,
          message: "Please enter a valid zipcode",
        }
      },
      type: "inputfield",
      isShow: true,
    },
  ];


  return (
    <>
      <Helmet>
        <title>Advertiser | 15Blades</title>
        
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Advertiser | 15Blades" />
        
      </Helmet>
      <Navbar />

       
      <section className="position-relative">
        <img src={bgStoryA} className="reach-bg" />
       
        <img className="bg-15g" src={bbg} alt=""/>
      <div class="container">
        <div class="form-blocker my-lg-5 my-4">  
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(registeradvertiser)} class="row justify-content-center">
              <div class="form-body row">

                {fields?.map((field, index) => {
                          return (
                            field.isShow && (
                              <React.Fragment key={index}>
                                {(() => {
                                  switch (field.type) {
                                    case "inputfield":
                                      return (
                                        <InputField key={index} {...field} />
                                      );
                                      case "radiofield":
                                      return (
                                        <RadioField key={index} {...field} />
                                      );
                                    case "textarea":
                                      return (
                                        <TextArea key={index} {...field} />
                                      );
                                    case "select":
                                      return (
                                        <SelectField key={index} {...field} />
                                      );
                                    case "heading":
                                      return <h4 className={field.className}>{field.label}</h4>;
                                    default:
                                      return null;
                                  }
                                })()}
                              </React.Fragment>
                            )
                          );
                        })}
                
                
                        <p className="mt-3">Before continuing please read the advertisement terms and conditions and state whether you agree with them or not.</p>
                     <div className="d-flex align-items-center">
                        <label className="check-box1">
                        <input type="checkbox" class="check"
                        {...register("check", {
                          required: "Please check the box",
                        })}
                        /><span class="checkbox"></span> </label>
                      <p className="ms-2 mb-0">I agree with the advertisement <a href="https://demoserver22.icwares.com/15blades/api/dev/termsandconditions">terms and conditions</a></p>
                    </div>
                    {errors.check && (
                        <span className="error">
                          {errors.check.message}
                        </span>
                      )}
                  <div class="row" id="error_div">

                  </div>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_SITE_KEY}
                      />
                    <input type="hidden" value="1" name="mode"/>
                    <div class="nxt-btn">
                    <button type="submit" className="btn btn-primary btn-style" disabled={isSubmitDisabled}>Submit</button>
                  </div>       
            </div>
        </form>
        <DevTool control={control} />
        </FormProvider>
        </div>
    </div>
      </section>

     
      <Footer />
    </>
  );
}

export default Advertise;
