import React from 'react'
import './Loader.css'

function Loader() {
  return (
    <div>
        <div className='loader'>
            <div className='line line-1'></div>
            <div className='line line-2'></div>
            <div className='line line-3'></div>
            <div className='line line-4'></div>
            <div className='line line-5'></div>
        </div>
    </div>
  )
}

export default Loader