import './App.css';
// import Navbar from './Components/Navbar';
// import Footer from './Components/Footer';
import Home from './Pages/Home';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import About from './Pages/About';
import Curators from './Pages/Curators';
import AdvisoryBoard from './Pages/AdvisoryBoard';
import Contact from './Pages/Contact';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Advertise from './Pages/Advertise';
// import Postdetail from './Pages/Postdetail';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { Helmet } from "react-helmet-async";


function App() {

  const location = useLocation();
  const env = process.env.REACT_APP_ENV;

  useEffect(() => {
    if(env=='production'){
      ReactGA.initialize('G-71SHPRPJMR');
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);
  
  return (
    <>
      {/* <BrowserRouter> */}
      <Helmet>
        {env=='production' && (
          <meta name="google-adsense-account" content="ca-pub-9820839400340318"/>
        )}
      </Helmet>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/curators' element={<Curators />} />
          <Route path='/advisoryboard' element={<AdvisoryBoard />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/registeradvertiser' element={<Advertise />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          {/* <Route path='/post/:id' element={<Postdetail/>} /> */}
          {/* <Route path='*' element={<Navigate to='/' />} /> */}
        </Routes>

      {/* </BrowserRouter> */}
    </>
  );
}

export default App;
