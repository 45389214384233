import React, { useEffect, useRef, useState } from "react";
import "../Assets/Css/OwlCarousel.css";
import OwlCarousel from "react-owl-carousel";
import bgImg from "../Assets/Images/bg.png";
import floatingPhone from "../Assets/Images/floating-phone.png";
import playstore from "../Assets/Images/play-store.png";
import appstore from "../Assets/Images/app-store.png";
import userfriendly from "../Assets/Images/user-friendly.png";
import network from "../Assets/Images/network.png";
import contents from "../Assets/Images/contents.png";
import information from "../Assets/Images/information.png";
import watchvideo from "../Assets/Images/vedio-watch.png";
import playbtn from "../Assets/Images/play-btn.png";
import lineStyle from "../Assets/Images/line-style.png";
import about from "../Assets/Images/about.jpg";
import bbg from "../Assets/Images/15b-bg.png";
import ios from "../Assets/Images/ios.png";
import antroid from "../Assets/Images/antroid.png";
import qr from "../Assets/Images/qr.png";
import bg from "../Assets/Images/bg.png";
import antroidbg from "../Assets/Images/antroid-bg.png";
import axios from "axios";
import CuratorCarousel from "../Components/CuratorCarousel";
import Loader from "../Components/Loader";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import md5 from "md5";
// import 'owl.carousel/dist/assets/owl.carousel.css'; // Import Owl Carousel styles
import 'owl.carousel/dist/assets/owl.theme.default.css'; // Import Owl Carousel default theme styles
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import CuratorProfile from "../Components/CuratorProfile";
// import introVedio from "../Assets/Images/intro-15blades.mp4"
import { Button, Modal } from "react-bootstrap";
import aboutVedio from "../Assets/Images/app-intro.mp4"

function Home() {

  const options = {
    items: 4,
    responsive: {
      1000: { items: 4 },
      900: { items: 2 },
      600: { items: 2 },
      500: { items: 2 },
      400: { items: 1 },
      300: { items: 1 },
      200: { items: 1 },

    },
    autoplay: true,
    autoplayTimeout: 10000,
    // autoplayHoverPause: true,
    pagination: false,
    nav: true, // Enable navigation arrows
    dots: false, // Enable pagination dots
  };

  const url = process.env.REACT_APP_API_URL + "/website/curators";
  const apiKey = process.env.REACT_APP_API_KEY;

  const [curator, setCurator] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCurators();
    window.scrollTo(0, 0);
  }, []);

  const timestamp = Date.now();
  const key = timestamp + apiKey;
  const encryptedBackendKey = md5(key)

  const headers = {
    'X-Secret-Key': encryptedBackendKey,
  };
  const data = {
    'timestamp': timestamp
  };

  const getCurators = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(url, data, { headers });
      // console.log("Response:", response.data);
      if (response.data.status === 1) {
        setCurator(response.data.data.curators);
        setIsLoading(false);
      } else {
        Swal.fire({
          icon: 'error',
          title: response.data.message,
          // text: error,
          timer: 3000,
        })
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [isVideoPlaying, setVideoPlaying] = useState(false);
  // const videoRef = useRef(null);
  // const myDivRef = useRef(null);

  // useEffect(() => {
  //     const handleScroll = () => {
  //     // const video = videoRef.current;
  //     // const scrollPosition = window.scrollY + window.innerHeight;
  //     //  const topOffset = myDivRef.current.offsetTop;

  //     if (scrollPosition > topOffset) {
  //       video.play();
  //     } else if (scrollPosition <= topOffset) {
  //       video.pause();
  //     }

  //   };

  //   // Attach event listener when component mounts
  //   window.addEventListener('scroll', handleScroll);

  //   // Cleanup function
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [isVideoPlaying]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Helmet>
        <title>Home | 15Blades</title>
        <meta
          name="description"
          content="Explore online surgery courses and more. Develop new skills to advance your career with 15 blades.A vibrant community of doctors, students and healthcare professionals, make learning more efficient and enjoyable.At 15 blades our main aim is to serve as an e-learning platform for core surgical knowledge and skills."
        />
        <meta
          name="keywords"
          content="15 blades, 15blades home, home, fifteen blades, micro-learning, medical media educational platform, medical eLearning, healthcare through connectivity, medical videos, surgical videos, surgery videos, portal for doctors, medical images, surgical eLearning, medical education online, platform for physicians, social eLearning for doctors, continuous medical education, surgical training, doctor's community, physicians, students, healthcare professionals, Learn Surgery Online, View our comprehensive free guides, resources , courses to help senior medical students, foundation doctors learn surgery online in 2023"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Home | 15Blades" />
        <meta
          property="og:description"
          content="Explore online surgery courses and more. Develop new skills to advance your career with 15 blades.A vibrant community of doctors, students and healthcare professionals, make learning more efficient and enjoyable.At 15 blades our main aim is to serve as an e-learning platform for core surgical knowledge and skills."
        />
      </Helmet>
      <Navbar />
      <section className="position-relative">
        <img className="bg-img-a" src={bgImg} alt="" />

        <div className="container">
          <div className="background-banner">
            <div className="row  ">
              <div className="col-9 mx-auto">
                <div className="row align-items-center">
                  <div className="col-md-6 col-12">
                    <div className="mob-banner">
                      <h1>Digital platform for everything medical</h1>
                      <p>
                        Join a vibrant community of doctors, students and
                        healthcare professionals
                      </p>
                      {/* <button type="button" className="btn btn-primary">
                        Get Started
                      </button> */}
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="banner-section">
                      <img
                        className="img-fluid banner-img"
                        src={floatingPhone} alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative">
        <img className="bg-img-b" src={bgImg} alt="" />
        <div className="container">


          <div className="dwld-section">

            <div className="row">

              <div className=" col-xl-5 col-lg-5 col-md-12 d-flex align-items-center">
                <div className="dwld-text">
                  <h5>Download the App</h5>
                  <p>Now on Play Store and App Store</p>
                </div>
              </div>

              <div class="col-xl-5 col-lg-4 col-md-6 ">
                <div className="dwnld">
                  <a href="https://play.google.com/store/apps/details?id=com.fifteenblades.app" target="blank">
                    <div className="dwld-app me-2">
                      <div className="me-1">
                        <img src={playstore} alt="" />
                      </div>
                      <div className="dwl-btn">
                        <p>Get it on</p>
                        <h6>Google Play</h6>
                      </div>
                    </div>
                  </a>

                  <a href="https://apps.apple.com/in/app/15blades/id6465287425" target="blank">
                    <div className="dwld-app">
                      <div className="me-1">
                        <img src={appstore} alt="" />
                      </div>
                      <div className="dwl-btn">
                        <p>Download on the</p>
                        <h6>App Store</h6>
                      </div>
                    </div>
                  </a>


                </div>


              </div>


              <div class="col-xl-2 col-lg-3  col-md-6 ">
                <div className="qr-scan">
                  <p className="or">OR</p>

                  <div class="text-center">
                    <p class="qr-text">Scan the QR Code</p>
                    <img className="qr-code" src={qr} alt="" />
                  </div>
                </div>
              </div>


            </div>
          </div>







          {/* 
          <div className="download">
            <div classs="row">
              <div className="col-md-12">
                <div className="dwld-btn-section-top">

                  <div>
                  <a href="https://play.google.com/store/apps/details?id=com.fifteenblades.app" target="blank">
                    <div className="dwld-app me-2">
                      <div className="me-1">
                        <img src={playstore} />
                      </div>
                      <div className="dwl-btn">
                        <p>Get it on</p>
                       
                        <h6>Google Play</h6>
                      </div>
                    </div>
                  </a>

                  <a href="https://apps.apple.com/in/app/15blades/id6465287425"  target="blank">
                    <div className="dwld-app">
                      <div className="me-1">
                        <img src={appstore} />
                      </div>
                      <div className="dwl-btn">
                        <p>Download on the</p>
                        <h6>App Store</h6>
                      </div>
                    </div>
                  </a>
                  </div>
                  <div class="text-center">
                   <p class="qr-text">Scan the QR Code</p>
                  <img className="qr-code" src={qr}/>
                  </div>

                </div>
              </div>
            </div>
          </div>  */}

          <div className="watch-video">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="row mob-space">
                  <div className="col-md-6">
                    <div className="card-style">
                      <div className="d-flex">
                        <img src={userfriendly} alt="" />
                        <h6>User-Friendly Interface</h6>
                      </div>
                      <p>
                        Quick access to various modules and resources, making
                        learning more efficient and enjoyable.
                      </p>
                    </div>
                    <div className="card-style">
                      <div className="d-flex">
                        <img src={network} alt="" />
                        <h6>Collaboration & Networking</h6>
                      </div>
                      <p>
                        Connect with fellow medical professionals, join
                        discussion forums, and participate in virtual study
                        groups,
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 top-space">
                    <div className="card-style">
                      <div className="d-flex">
                        <img src={contents} alt="" />
                        <h6>Engaging Contents</h6>
                      </div>
                      <p>
                        From videos, polls and case studies, we provide a
                        variety of tools to promote active learning{" "}
                      </p>
                    </div>

                    <div className="card-style">
                      <div className="d-flex">
                        <img src={information} alt="" />
                        <h6>Reliable Health Information</h6>
                      </div>
                      <p>
                        Access to healthcare professionals curated and
                        evidence-based health content.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <div className="watch-media">
                  <h5>Watch Video </h5>
                  <h4>Healthcare through connectivity</h4>
                  <p className="media-text">
                    Whether you are a student, resident, or seasoned
                    practitioner, our app will provide you with the tools and
                    resources to help you excel in your medical journey
                  </p>
                  <div className="video mob-space">



                    <Modal
                      show={show}
                      onHide={handleClose}
                      // backdrop="static"
                      keyboard={false}
                      className="vedioModal"
                      dialogClassName="modal-md"
                    >



                      <button type="button" className="btn-close" onClick={handleClose}></button>

                      <video
                        src={aboutVedio} type="video/mp4" className="vedio-blocker" frameborder="0" allowfullscreen="" autoplay="true" controls="true" loop="true" playsinline="" muted="">
                      </video>



                    </Modal>



                    <img className="img-fluid" src={watchvideo} alt="" onClick={handleShow} />
                    {/* <a >
                      <img className="play-btn" src={playbtn} />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="About">
            <div className="row">
              <div className="col-lg-6 col-12 order-lg-1 order-2">
                <div className="about-img">
                  <div className="position-relative">
                    <img className="img-fluid line-style-b" src={lineStyle} alt="" />
                    <img className="img-fluid about-image" src={about} alt="" />
                    <img className="img-fluid line-style" src={lineStyle} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 order-lg-2 order-1">
                <h5>About Us</h5>
                <h4>Connecting, Innovating, Transforming</h4>
                <p className="media-text">
                  We are a clinician only peer to peer medical forum platform that focuses on micro-learning to keep pace with todays healthcare professional. We have created a micro-learning environment where short form videos and posts are curated for your experience. We welcome healthcare professionals to participate as a life-long student and educator to advance the spread of medical knowledge and discourse.
                </p>

                <Link to="/about" onClick={() => window.scrollTo(0, 0)}>
                  <button className="btn-secondary">
                    Read More
                    <svg
                      className="w-6 h-6"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                      ></path>
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img className="bg-15g" src={bbg} alt="" />
      </section>

      {/* <section >
        <div className="container">
          <div className="Curators">
            <div className="row text-center">
              <div className="col-md-12">
                <h5>Meet Our Curators </h5>
                <h4>Introducing Our Expert Curators</h4>
                {}
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section>
       

       
        {curator?.length > 0 ? (
          <div>
            <OwlCarousel className="owl-theme carousel-style" {...options}>
        
              {curator?.map((item,index) => {
                return <CuratorCarousel data={item} key={index} />;
              })}
            
            </OwlCarousel>
          </div>
        ) : (
          isLoading && <Loader />
        )}
    
      </section> */}

      <section className="position-relative" >
        <div className="container">
          <div className="available">
            <div className="row align-items-center">
              <div className="col-lg-7 col-12 order-1 order-lg-0">
                <div className="mob-style">
                  <h4>
                    Available on
                    <br />
                    <span>Android & iOS</span>
                  </h4>
                  <p className="media-text">
                    Experience the power of our app on your preferred platform.
                    {/* Download it today from the Google Play Store or the App
                    Store */}
                  </p>






                  <div className="download">

                    <div className="dwld-btn-section">

                      <div className="dwlod-bottom">
                        <a href="https://play.google.com/store/apps/details?id=com.fifteenblades.app" target="blank">
                          <div className="dwld-app me-2 mt-0">
                            <div className="me-1">
                              <img src={playstore} alt="" />
                            </div>
                            <div className="dwl-btn">
                              <p>Get it on</p>

                              <h6>Google Play</h6>
                            </div>
                          </div>
                        </a>

                        <a href="https://apps.apple.com/in/app/15blades/id6465287425" target="blank">
                          <div className="dwld-app">
                            <div className="me-1">
                              <img src={appstore} alt="" />
                            </div>
                            <div className="dwl-btn">
                              <p>Download on the</p>

                              <h6>App Store</h6>
                            </div>

                          </div>
                        </a>
                      </div>



                      <div class="qr-scan-bottom">
                        <p className="or-bottom">OR</p>
                        <div className="dowld-bottom-qr">
                          <p class="qr-text">Scan the QR Code</p>
                          <img className="qr-code" src={qr} alt="" />
                        </div>
                      </div>


                    </div>

                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-12 order-0 order-lg-1 mb-lg-0 mb-3">
                <div className="position-relative d-flex justify-content-center">
                  <img className="img-fluid ios" src={ios} alt="" />
                  <img className="img-fluid antroid" src={antroid} alt="" />
                  <img src={bg} className="img-fluid background-bg" alt="" />
                  {/* <video ref={videoRef} className="intro-vedio"
                    src={introVedio} type="video/mp4" frameborder="0" allowfullscreen="" autoplay loop="true" playsinline="" muted="">
                  </video> */}

                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="img-fluid antroid-bg" src={antroidbg} alt="" />
      </section>
      <Footer />
    </>
  );
}

export default Home;
