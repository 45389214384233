import React from 'react'
import poweredBy from '../Assets/Images/powered-by.png'
import logo from '../Assets/Images/logo.png'
import { Link } from 'react-router-dom'

function Footer() {
    const advertiserLogin = process.env.REACT_APP_ADVERTISER_URL+"register";

    return (
        <>
            <footer>
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-xl-3 col-12  order-0 order-xl-0">
                            <div className="ftr-logo-style">
                                <Link to="/" onClick={() => window.scrollTo(0, 0)}><img src={logo} className="img-fluid  footer-logo" /></Link>
                            </div>
                        </div>

                        {/* <div className="col-md-12">
                                <p className="Copyright footer-spacing">© {(new Date().getFullYear())} 15bladesmedia, Inc All Rights Reserved.</p>
                            </div> */}


                        <div className="col-xl-4 col-12 order-1 order-xl-1">
                            <nav>
                                <ul>
                                    <li><Link to="/" onClick={() => window.scrollTo(0, 0)}>Home</Link></li>
                                    <li><Link to="/about" onClick={() => window.scrollTo(0, 0)}>About Us</Link></li>
                                    {/* <li><Link to="/curators" onClick={()=>  window.scrollTo(0, 0)}>Curators</Link></li> */}
                                    <li><Link to="/advisoryboard" onClick={() => window.scrollTo(0, 0)}>Advisory Board</Link></li>
                                    <li><a href="https://15bladesmerch.com/" target="_blank" >Merchandise</a></li>
                                    <li><Link to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact Us</Link></li>
                                </ul>
                            </nav>
                        </div>


                        <div className="col-xl-2 col-12  order-4 order-xl-2  ">
                            <div className="social-media">
                                <ul className="btn-body mt-3">
                                    {/* <li><Link className="btn-secondary" to="/registeradvertiser" onClick={() => window.scrollTo(0, 0)}>Advertise With Us</Link></li> */}
                                    <li><a className="btn-secondary" href={advertiserLogin} target="_blank">Advertise With Us</a></li>
                                </ul>
                            </div>
                        </div>



                        <div className="col-xl-3 col-12  order-4 order-xl-2 ">
                            <div className="social-media">
                                <ul className="btn-body">
                                    {/* <li><Link className="btn-secondary" to="/registeradvertiser" onClick={() => window.scrollTo(0, 0)}>Advertise With Us</Link></li> */}
                                    {/* <li><a className="btn-secondary" href={advertiserUrl} target="_blank">Advertise With Us</a></li> */}
                                    {/* <li><a className="pt-1" href="#"><i className="fa-brands fa-facebook-f fa-2xs"></i></a></li> */}
                                    <li><a href="https://www.facebook.com/profile.php?id=61556268497429&mibextid=LQQJ4d" target="_blank"><i className="fa-brands fa-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/15blades_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank"><i className="fa-brands fa-instagram"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/15bladesmediainc" target="_blank"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                    <li><a href="https://youtube.com/@15blades_?si=iklqJrQBxrI7RnY0" target="_blank"><i className="fa-brands fa-youtube"></i></a></li>
                                    <li><a href="https://x.com/15blades_?s=11&t=-6Ma9hrHFFemxERjxtxNeA" target="_blank"><i className="fa-brands fa-x-twitter"></i></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-3 col-12  order-3 order-xl-3">

                            <p className="Copyright footer-spacing">© {(new Date().getFullYear())} 15bladesmedia, Inc All Rights Reserved.</p>

                        </div>


                        <div className="col-xl-4 col-12 order-2 order-xl-4">
                            <div className="contact footer-spacing">
                                <ul>
                                    <li><i className="fa-solid fa-envelope"></i> contact@15Blades.com</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-5 col-12 order-5 order-xl-5">
                            <div className="com-md-12">
                                <div className="powered-spacing">
                                    <img src={poweredBy} className="img-fluid" />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer