import React, { useEffect, useState } from "react";
import bgStoryA from "../Assets/Images/bg-story-a.png";
import bgStoryB from "../Assets/Images/bg-story-b.png";
import antroidbg from "../Assets/Images/antroid-bg.png";
import cardImg from "../Assets/Images/card-img.png";
import axios from "axios";
import CuratorProfile from "../Components/CuratorProfile";
import Loader from "../Components/Loader";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import md5 from 'md5';
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import NoData from "../Components/NoData";

function Curators() {

  const url = process.env.REACT_APP_API_URL+'/website/curators';
  const apiKey = process.env.REACT_APP_API_KEY;

  const [curator,setCurator] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCurators();
    window.scrollTo(0, 0);
  },[])

  const timestamp = Date.now();
  const key= timestamp+apiKey;
  const encryptedBackendKey = md5(key)

  const headers = {
    'X-Secret-Key': encryptedBackendKey,
  };
  const data = {
    'timestamp': timestamp 
  };

  const getCurators = async ()=>{
    try {
      setIsLoading(true);
      const response = await axios.post(url,data ,{ headers });
        // console.log('Response:', response.data);
        if(response.data.status === 1){
          setCurator(response.data.data.curators);
          setIsLoading(false);
        }else{
          Swal.fire({
            icon: 'error',
            title: response.data.message,
            // text: error,
            timer: 3000,
          })
        }
    } catch (error) {
        console.error('Error:', error);
    }
  }

  return (
    <>
      <Helmet>
        <title>Curators | 15Blades</title>
        <meta
          name="description"
          content="Discover the brilliant minds behind our curated collection as our expert curators share their deep knowledge, unique perspectives, and captivating stories.Curate valuable information and organizing it in a way that enlightens and inspires others."
        />
        <meta
          name="keywords"
          content="curators, 15 blades curators, medical curators, expert medical curators, exert medical professionals, master of curation, expert curators"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Curators | 15Blades" />
        <meta
          property="og:description"
          content="Discover the brilliant minds behind our curated collection as our expert curators share their deep knowledge, unique perspectives, and captivating stories.Curate valuable information and organizing it in a way that enlightens and inspires others."
        />
      </Helmet>
      <Navbar />
      <section className="about-banner curation-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-6 col-md-8 mx-auto">
                  <div className="text-content ">
                    <h2> Masters of Discovery and Curation</h2>
                  </div>
                </div>

                <div className="col-lg-8 mx-auto">
                  <div className="curators-prg">
                    <p className="mx-auto">
                      Discover the brilliant minds behind our curated collection
                      as our expert curators share their deep knowledge, unique
                      perspectives, and captivating stories.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={bgStoryA} className="img-fluid Curation-bg" />
      </section>
      

      <section className="profile-section">
        <img src={bgStoryB} className="img-fluid profile-bg"/>
        <div className="container">
          <div className="row   justify-content-center">
            <div className="col-md-12">
            {(curator?.length > 0) ? (
              <div className="row">
              {curator?.map( (item) => {
                  return(
                      <CuratorProfile data={item} key={item.curator_uuid}/>
                  )
                })
              }
              </div>
              ) : (
                isLoading ? <Loader /> : <NoData/>
                ) 
            }               
              
            </div>
          </div>
        </div>
        <img src={antroidbg} className="img-fluid profile-bottom-bg" />
      </section>

      <section>
        <div className="container">
          <div className="curator-card">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <div className="card-text">
                      <h3>
                        How to be a <span>curator</span>
                      </h3>
                      <p>
                        Are you passionate about curating valuable information
                        and organizing it in a way that enlightens and inspires
                        others? If so, we invite you to embark on a fulfilling
                        journey as a curator on our platform.
                      </p>
                      <Link to="/contact" onClick={()=>  window.scrollTo(0, 0)}>
                        <button className="btn-secondary Contact-Us-card">
                          <div className="">Contact Us</div>
                          <svg
                            className="w-6 h-6"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                              strokeLinejoin="round"
                              strokeLinecap="round"
                            ></path>
                          </svg>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <img src={cardImg} className="img-fluid card-img" />
              </div>
            </div>
            <img src={bgStoryA} className="img-fluid  card-bg" />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Curators;
