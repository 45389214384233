import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

function SelectField({ name, label, rules, options, className }) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <div className={className}>

      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={rules}
        render={({ field }) => (
          <select {...field} className="">
            <option className='d-none' value="" disabled></option>
            {options?.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        )}
      />
        <div className="underline"></div>
        {label && <><label htmlFor={name}>{label}<span className="error">*</span></label></> }

      {errors[name] && <span className='error'>{errors[name].message}</span>}
    </div>
  );
}

export default SelectField;
