import React, { useEffect, useState } from 'react'
import badge from '../Assets/Images/badge.svg'
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import md5 from 'md5';

function RequestModal() {

    const requestFormURL = process.env.REACT_APP_API_URL+'/website/requestaccess';
    const apiKey = process.env.REACT_APP_API_KEY;

    const [isModalVisible, setModalVisibility] = useState(false);

    const openModal = () => {
        reset();
        setModalVisibility(true);
    };
    const hideModal = () => {
        setModalVisibility(false);
    };

    const {
      register,
      handleSubmit,
      setError,
      formState: { errors },
      reset
    } = useForm();

    const timestamp = Date.now();
    const key= timestamp+apiKey;
    const encryptedBackendKey = md5(key)

    const headers = {
        'X-Secret-Key': encryptedBackendKey,
    };

    const requestSubmit = async (requestData) => {    
        try {
    
            const data = {...requestData, ...{timestamp}}
            const response = await axios.post(requestFormURL, data, { headers });
        //   console.log(data);
        //   console.log(response.data);
          if(response.data.status === 1){
            Swal.fire({
                icon: 'success',
                title: response.data.message,
                // text: 'Thank you for your submission.',
                timer: 3000,
            })
            hideModal();
            reset();
          }else{
            // console.log(response.data);
            if(response.data.type === 'Validation_error'){
                setError('email', { type: 'custom', message: response.data.message });
            }else{
                Swal.fire({
                icon: 'error',
                title: 'Error submitting the form',
                text: response.data.message,
                timer: 3000,
                })
            }
          }
          
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error submitting the form',
            text: error,
            timer: 3000,
          })
          console.error('Error submitting the form:', error);
        }
      };

  return (
    <>
        {/* <button type="button" className="btn btn-primary" onClick={openModal}>Request VIP Access</button> */}

        <Modal
            show={isModalVisible}
            onHide={hideModal}
            size="lg"
            className="vpn-modal"
            centered
        >
        
            <Modal.Body>
                <button type="button" className="btn-close" onClick={hideModal}></button>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="modal-l">
                            <h2>Request VIP Access</h2>
                            <p>VIP access allows you to get premium account access.</p>
                            <img src={badge} className="modal-gif" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="d-flex h-100 align-items-center">
                            <form onSubmit={handleSubmit(requestSubmit)}>
                                <div className="form-modal">
                                    <div className="title">Enter Your Details</div>
                                    <div className="subtitle">Let us know if you wish to get a VIP access</div>
                                    <div className="input-container ic1">
                                        <input id="firstname" 
                                                className="input error" 
                                                type="text" 
                                                placeholder=" " 
                                                {...register('name',{
                                                        required: "Name is required",
                                                })}/>
                        
                                        <label htmlFor="firstname" className="placeholder error">Name <span className="error">*</span></label>
                                    </div>
                                    {errors.name && <span className="error">{errors.name.message}</span>}

                                    <div className="input-container ic2">
                                        <input 
                                                id="email" 
                                                className="input" 
                                                type="text" 
                                                placeholder=" " 
                                                {...register('email', {
                                                    required: "Email is required",
                                                    pattern: {
                                                      value:  /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
                                                      message: 'Please enter a valid email address',
                                                    },
                                                })}/>
            
                                        <label htmlFor="email" className="placeholder">Email <span className="error">*</span></label>
                                    </div>
                                    {errors.email && <span className="error">{errors.email.message}</span>}

                                    <div className="input-container ic2">
                                        <input id="phonenumber" 
                                                className="input" 
                                                type="text" 
                                                placeholder=" " 
                                                {...register('phone_number',{
                                                    required: "Phone number is required",
                                                    pattern: {
                                                    //   value:  /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/,
                                                      value:  /^(?:\+\d{1,3}[. -]?)?(\([2-9]\d{2}\)|[2-9]\d{2})[. -]?[2-9]\d{2}[. -]?\d{4}$/,
                                                      message: 'Please enter a valid phone number',
                                                    },
                                                })}/>
                                    
                                        <label htmlFor="phonenumber" className="placeholder">Phone Number <span className="error">*</span></label>
                                    </div>
                                    {errors.phone_number && <span className="error">{errors.phone_number.message}</span>}
                                    <div>
                                        <button type="submit" className="btn btn-primary mt-3 submit-btn sub-style " >Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal.Body>
            
        </Modal>

    </>
  )
}

export default RequestModal