import React from "react";
import "./PrivacyPolicy.css";
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | 15Blades</title>
        
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Privacy Policy | 15Blades" />
        
      </Helmet>
      <Navbar />
      <section>
        <h1>15bladesmedia, INC. Privacy Policy</h1>
        <p>
          This Privacy Policy explains how information about you is collected,
          used and disclosed by 15bladesmedia, Inc. (“15blades”). This Privacy
          Policy applies to information we collect when you use our website,
          mobile application, and other online products and services
          (collectively, the “Services”) or when you otherwise interact with us.{" "}
        </p>
        <p>
          We may change this Privacy Policy from time to time. If we make
          changes, we will notify you by revising the date at the top of the
          policy and, in some cases, we may provide you with additional notice
          (such as adding a statement to our homepage or sending you a
          notification). We encourage you to review the Privacy Policy whenever
          you access the Services or otherwise interact with us to stay informed
          about our information practices and the ways you can help protect your
          privacy
        </p>
      </section>
      <section>
        <h1>Collection of Information</h1>
        <p>
          Information You Provide to Us. We collect information you provide
          directly to us. For example, we collect information when you create an
          account, participate in any interactive features of the Services, fill
          out a form, apply for a job, communicate with us via third party
          social media sites, request customer support or otherwise communicate
          with us. The types of information we may collect include your name,
          email address, postal address, phone number, fax number, specialty and
          sub-specialty, workplace information, and any other information you
          choose to provide. Information We Collect Automatically When You Use
          the Services. When you access or use our Services, we automatically
          collect information about you, including:
        </p>
        <ul>
          <li>
            Log Information: We collect log information about your use of the
            Services, including the type of browser you use, access times, pages
            viewed, your IP address, impressions shown, and the page you visited
            before navigating to our Services.
          </li>
          <li>
            Device Information: We collect information about the computer or
            mobile device you use to access our Services, including the hardware
            model, operating system and version, unique device identifiers and
            mobile network information.
          </li>
          <li>
            Information Collected by Cookies and Other Tracking Technologies: We
            and our service providers use various technologies to collect
            information, including cookies and web beacons. Cookies are small
            data files stored on your hard drive or in device memory that help
            us improve our Services and your experience, identify your current
            working session, see which areas and features of our Services are
            popular and count visits. Web beacons are electronic images that may
            be used in our Services or emails and help deliver cookies, count
            visits and understand usage and campaign effectiveness. For more
            information about cookies, and how to disable them, please see “Your
            Choices” below.
          </li>
        </ul>
      </section>

      <section>
        <h1>Information We Collect From Other Sources</h1>
        <p>
          We may also obtain information from other sources and combine that
          with information we collect through our Services. For example, we may
          collect information about you from third parties, including but not
          limited to analytics services and mailing list providers, if you
          follow us through a social media site, we will have access to certain
          information from that site, such as your name and account information,
          in accordance with the authorization procedures determined by such
          social media site.
        </p>
        <h1>Use of Information</h1>
        <p>
          We may use information about you for various purposes, including to :
        </p>
        <ul>
          <li>Provide, maintain and improve our Services;</li>
          <li>
            Provide and deliver the services you request, process transactions
            and send you related information;
          </li>
          <li>
            Send you technical notices, updates, security alerts and support and
            administrative messages;
          </li>
          <li>
            Respond to your comments, questions and requests and provide
            customer service;
          </li>
          <li>
            Communicate with you about products, services, offers, promotions,
            rewards, and events offered by 15bladesmedia and others, and provide
            news and information we think will be of interest to you;
          </li>
          <li>
            Monitor and analyze trends, usage and activities in connection with
            our Services;
          </li>
          <li>
            Detect, investigate and prevent fraudulent transactions and other
            illegal activities and protect the rights and property of
            15bladesmedia and others
          </li>
          <li>
            Personalize and improve the Services and provide advertisements,
            content or features that match user profiles or interests;
          </li>
          <li>
            Link or combine with information we get from others to help
            understand your needs, confirm your identity, and provide you with
            better service; and
          </li>
          <li>
            Carry out any other purpose for which the information was collected.
          </li>
        </ul>
        <h1>Sharing of Information</h1>
        <p>
          We may share information about you as follows or as otherwise
          described in this Privacy Policy:
        </p>
        <ul>
          <li>
            With vendors, consultants and other service providers who need
            access to such information to carry out work on our behalf;
          </li>
          <li>
            With other users of the Services when you post or upload content in
            a publicly-accessible area of the Services;
          </li>
          <li>
            We may provide your Personal Information and/or Non-Personally
            Identifiable Information with our current or prospective
            advertisers, sponsors and other business partners. This may include
            your name, your specialty, and/or some information so that the
            advertiser may evaluate your engagement as well as the performance
            of its campaign.
          </li>
          <li>
            In response to a request for information if we believe disclosure is
            in accordance with, or required by, any applicable law, regulation
            or legal process;
          </li>
          <li>
            If we believe your actions are inconsistent with our user agreements
            or policies, or to protect the rights, property and safety of
            15bladesmedia or others;
          </li>
          <li>
            In connection with, or during negotiations of, any merger, sale of
            company assets, financing or acquisition of all or a portion of our
            business by another company;
          </li>
          <li>
            Between and among 15bladesmedia and its current and future parents,
            affiliates, subsidiaries and other companies under common control
            and ownership; and
          </li>
          <li>With your consent or at your direction.</li>
        </ul>
        <p>
          We may also share aggregated or de-identified information, which
          cannot reasonably be used to identify you.
        </p>
      </section>

      <section>
        <h1>Social Sharing Features</h1>
        <p>
          The Services may offer social sharing features and other integrated
          tools (such as the Facebook “Like” button). Your use of such features
          enables the sharing of information with your friends or the public,
          depending on the settings you establish with the entity that provides
          the social sharing feature. For more information about the purpose and
          scope of data collection and processing in connection with social
          sharing features, please visit the privacy policies of the entities
          that provide these features.
        </p>
      </section>

      <section>
        <h1>Advertising and Analytics Services Provided by Others</h1>
        <p>
          We may allow others to provide analytics services and serve
          advertisements on our behalf across the Internet and in applications.
          These entities may use cookies, web beacons, device identifiers and
          other technologies to collect information about your use of the
          Services and other websites and applications, including your IP
          address, web browser, mobile network information, pages viewed, time
          spent on pages or in apps, links clicked and conversion information.
          This information may be used by 15bladesmedia and others to, among
          other things, analyze and track data, determine the popularity of
          certain content, deliver advertising and content targeted to your
          interests on our Services and other websites and better understand
          your online activity. For more information about interest-based ads,
          or to opt out of having your web browsing information used for
          behavioral advertising purposes, please visit
          www.aboutads.info/choices. Your device may also include a feature
          (“Limit Ad Tracking” on iOS or “Opt Out of Interest-Based Ads” on
          Android) that allows you to opt out of having certain information
          collected through apps used for behavioral advertising purposes.
        </p>
        <p>
          We may also work with other websites or platforms (such as Facebook
          etc.) to serve ads to you as part of a customized campaign, unless you
          notify us that you prefer not to have information about you used in
          this way. To opt out, email us at{" "}
          <a href="mailto:contact@15blades.com">contact@15blades.com.</a>
        </p>
      </section>

      <section>
        <h1>Security</h1>
        <p>
          15bladesmedia takes reasonable measures to help protect information
          about you from loss, theft, misuse and unauthorized access,
          disclosure, alteration and destruction.
        </p>
      </section>

      <section>
        <h1>Transfer of Information to the U.S. and Other Countries</h1>
        <p>
          15bladesmedia is based in the United States and the information we
          collect is governed by U.S. law. By accessing or using the Services or
          otherwise providing information to us, you consent to the processing,
          transfer and storage of information in and to the U.S. and other
          countries, where you may not have the same rights and protections as
          you do under local law.
        </p>
      </section>

      <section>
        <h1>Your Choices</h1>
        <p>
          Account Information. You may update, correct or delete information
          about you at any time by logging into your online account or emailing
          us at
          <a href="mailto:contact@15blades.com">contact@15blades.com</a>. If you
          wish to deactivate your account, please email us at{" "}
          <a href="mailto:contact@15blades.com">contact@15blades.com</a>, but
          note that we may retain certain information as required by law or for
          legitimate business purposes. We may also retain cached or archived
          copies of information about you for a certain period of time.
        </p>
        <p>
          Cookies. Most web browsers are set to accept cookies by default. If
          you prefer, you can usually choose to set your browser to remove or
          reject browser cookies. Removing or rejecting browser cookies does not
          necessarily affect third party flash cookies used in connection with
          our Services. To delete or disable flash cookies please visit
          helpx.adobe.com/
          flash-player/kb/disable-local-shared-objects-flash.html for more
          information. Please note that if you choose to remove or reject
          cookies, this could affect the availability and functionality of our
          Services.
        </p>
        <p>
          Promotional Communications. You may opt out of receiving promotional
          emails or text messages from 15bladesmedia by emailing us at
          <a href="mailto:contact@15blades.com">contact@15blades.com</a>. If you
          opt out, we may still send you non-promotional communications, such as
          those about your account or our ongoing business relations.
        </p>
        <p>
          Mobile Push Notifications/Alerts. With your consent, we may send
          promotional and non-promotional push notifications or alerts to your
          mobile device. You can deactivate these messages at any time by
          changing the notification settings on your mobile device.
        </p>
      </section>

      <section>
        <h1>Contact Us</h1>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at:
          <a href="mailto:contact@15blades.com">contact@15blades.com</a>
        </p>
      </section>

      <section>
        <h1>Effective Date of This Privacy Policy</h1>
        <p>This Privacy Policy is effective as of July, 2023.</p>
      </section>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
