import React, { useEffect, useState } from "react";
import bgStoryA from "../Assets/Images/bg-story-a.png";
import bgStoryB from "../Assets/Images/bg-story-b.png";
import antroidbg from "../Assets/Images/antroid-bg.png";
import cardImg from "../Assets/Images/card-img.png";
import axios from "axios";
import AdvisoryBoardProfile from "../Components/AdvisoryBoardProfile";
import Loader from "../Components/Loader";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import md5 from 'md5';
import { Helmet } from "react-helmet-async";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import NoData from "../Components/NoData";

function AdvisoryBoard() {

  const url = process.env.REACT_APP_API_URL + '/website/advisoryboard';
  const apiKey = process.env.REACT_APP_API_KEY;


  const [advisoryboard, setAdvisoryBoard] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAdvisoryBoard();
    window.scrollTo(0, 0);
  }, [])

  const timestamp = Date.now();
  const key = timestamp + apiKey;
  const encryptedBackendKey = md5(key)

  const headers = {
    'X-Secret-Key': encryptedBackendKey,
  };
  const data = {
    'timestamp': timestamp
  };

  const getAdvisoryBoard = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(url, data, { headers });
      console.log('Response:', response.data);
      if (response.data.status === 1) {
        setAdvisoryBoard(response.data.data.advisoryBoardData);
        setIsLoading(false);
      } else {
        Swal.fire({
          icon: 'error',
          title: response.data.message,
          // text: error,
          timer: 3000,
        })
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <>
      <Helmet>
        <title>AdvisoryBoard | 15Blades</title>
        <meta
          name="description"
          content="Discover the brilliant minds behind our curated collection as our expert AdvisoryBoard share their deep knowledge, unique perspectives, and captivating stories.Curate valuable information and organizing it in a way that enlightens and inspires others."
        />
        <meta
          name="keywords"
          content="AdvisoryBoard, 15 blades AdvisoryBoard, medical AdvisoryBoard, expert medical AdvisoryBoard, exert medical professionals, master of curation, expert AdvisoryBoard"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AdvisoryBoard | 15Blades" />
        <meta
          property="og:description"
          content="Discover the brilliant minds behind our curated collection as our expert AdvisoryBoard share their deep knowledge, unique perspectives, and captivating stories.Curate valuable information and organizing it in a way that enlightens and inspires others."
        />
      </Helmet>
      <Navbar />
      <section className="about-banner curation-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-6 col-md-8 mx-auto">
                  <div className="text-content ">
                    <h2>Advisory Board</h2>
                  </div>
                </div>

                <div className="col-lg-8 mx-auto">
                  <div className="AdvisoryBoard-prg">
                    <p className="mx-auto">
                      {/* Discover the brilliant minds behind our curated collection
                      as our expert AdvisoryBoard share their deep knowledge, unique
                      perspectives, and captivating stories. */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={bgStoryA} className="img-fluid Curation-bg" />
      </section>


      <section className="profile-section">
        <img src={bgStoryB} className="img-fluid profile-bg" />
        <div className="container">
          <div className="row   justify-content-center">
            <div className="col-md-12">
              {(advisoryboard?.length > 0) ? (
                <div className="row justify-content-center">
                  {advisoryboard?.map((item) => {
                    return (
                      <AdvisoryBoardProfile data={item} key={item.advisory_board_uuid} />
                    )
                  })
                  }
                </div>
              ) : (
                isLoading ? <Loader /> : <NoData/>
              )
              }

            </div>
          </div>
        </div>
        <img src={antroidbg} className="img-fluid profile-bottom-bg" />
      </section>


      <Footer />
    </>
  );
}

export default AdvisoryBoard;
