import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function MyVerticallyCenteredModal(props) {
  const renderTextWithLineBreaks = () => {
    const html = props.data.bio_snippet.replace(/\n/g, '<br />');
    return { __html: html };
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-margin" // Add your custom class here
    >
      <Modal.Header className="header-none" closeButton>
        <img src={props.data.profile_image} className="img-fluid prf-modal" />
        <Modal.Title id="contained-modal-title-vcenter">

        </Modal.Title>
      </Modal.Header >
      <Modal.Body className="prf-modal-body">

        <h4>{props.data.name} <span>{props.data.title}</span></h4>
        <p className="dr-position">{props.data.specialities}</p>
        <p className="prf-description" dangerouslySetInnerHTML={renderTextWithLineBreaks()}></p>
        {/* <p className="prf-mail">{props.data.email}</p> */}

        <div className="prf-socialmedia">
          {props.data.facebook_link && <a href={props.data.facebook_link}><i className="fa-brands fa-facebook-f"></i></a>}
          {props.data.linkedin_link && <a href={props.data.linkedin_link}><i className="fa-brands fa-linkedin-in"></i></a>}
          {props.data.twitter_link && <a href={props.data.twitter_link}><i className="fa-brands fa-x-twitter"></i></a>}
        </div>


      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

function CuratorProfile({ data }) {
  // console.log(data)
  const [modalShow, setModalShow] = React.useState(false);

  const bio_snippet = () => {
    const html = data.bio_snippet.replace(/\n/g, '<br />');
    return { __html: html };
  };

  return (
    <>
      {/* <div className="profile" key={data.curator_uuid}>
        <a onClick={() => setModalShow(true)}>
          <img src={data.profile_image} className="img-fluid " />
          <div className="bottom-name">
            <h5>{data.name} <span>{data.title}</span></h5>
            <p>{data.specialities}</p>
          </div>
        </a>
      </div> */}


      <div class="col-lg-4 col-md-6">
        <div className="curators-prf" key={data.curator_uuid}>
          <a onClick={() => setModalShow(true)}>
            <img src={data.profile_image} className="img-fluid prf-curts" />
            <div className="curts-name">
              <h5>{data.name} <span>{data.title}</span></h5>
              <p className="curts-designation">{data.specialities}</p>
              <p className="curts-paragraphs " dangerouslySetInnerHTML={bio_snippet()}></p>
            </div>
          </a>
        </div>
      </div>




      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
    </>
  );
}

export default CuratorProfile;
